@use "../base/vars" as *;

html{
    scroll-behavior: smooth;
}

.header{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    padding: 45px 20px;
    
    button.open{
        display: none;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 8;
        
        @include media_query(){
            display: block;
        }
    }
    
    .close{
        display: none;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 8;
        
        @include media_query(){
            display: block;
        }
    }
    
    .navigation{
        width: 100%;
        
        @include media_query(){
            visibility: hidden;
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            background-color: $main-color;
            
            transition: visibility .25s, opacity .25s;
        }
        
        &.open{
            @include media_query(){
                visibility: visible;
                opacity: 1;
            }
        }
        
        ul{
            display: flex;
            justify-content: center;
            gap: 40px;
            
            @include media_query(){
                width: 100vw;
                height: 100vh;
                flex-direction: column;
                align-items: center;
            }
            
            li{
                a{
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    color: #fff;
                    padding-bottom: .5em;
                    border-bottom: solid 1px transparent;
                    letter-spacing: .05em;
                    
                    transition: border-bottom-color .25s;
                    
                    &:hover{
                        border-bottom-color: #fff;
                    }
                }
                
                &.moriden{
                    display: none;
                    padding-top: 40px;
                    
                    @include media_query(){
                        display: block;
                    }
                }
            }
        }
    }
}


.footer{
    position: relative;
    background-color: $main-color;
    
    .footer_inner{
        text-align: center;
        padding: 85px 20px 45px;
        
        @include media_query(){
            padding: 80px 20px 30px;
        }
        
        .wald{
            width: 100%;
            max-width: 186px;
            height: auto;
        }
        
        a{
            display: inline-block;
            margin-top: 65px;
            
            transition: opacity .25s;
            
            &:hover{
                opacity: .7;
            }
        }
    }
    
    .copy{
        position: absolute;
        right: 45px;
        bottom: 50px;
        color: #fff;
        writing-mode: vertical-rl;
        @include rem(11);
        letter-spacing: .06em;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        
        @include media_query(){
            position: static;
            writing-mode: horizontal-tb;
            text-align: center;
            padding-bottom: 50px;
        }
    }
}
