@use "../base/vars" as *;

.firstview{
    width: 100%;
    height: 100vh;
    background-image: url(../img/main.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
    display: grid;
    place-items: center;
    
    @include media_query(){
        background-image: url(../img/main_sp.webp);
    }
    
    h1{
        width: 100%;
        text-align: center;
    }
    
    img{
        width: 100%;
        max-width: 434px;
        height: auto;
        
        @include media_query(){
            max-width: 250px;
        }
    }
}




.split_section{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 19.548%;
    
    @include media_query(){
        grid-template-columns: 1fr;
        gap: 50px;
    }
    
    .section_title{
        h2{
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            writing-mode: vertical-rl;
            color: $main-color;
            @include rem(78);
            
            @include media_query(){
                writing-mode: horizontal-tb;
                @include rem(56);
            }
            
            span{
                display: block;
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: bold;
                @include rem(16);
                letter-spacing: .04em;
                
                @include media_query(){
                    @include rem(15);
                }
            }
        }
    }
}




.about{
    padding: 115px 20px 100px;
    background-color: #fff;
    
    @include media_query(){
        padding: 60px 25px 100px;
    }
    
    .about_inner{
        
        max-width: 885px;
        margin: 0 auto;
        
        .section_main{
            h3{
                margin-top: -10px;
                @include rem(34);
                color: $main-color;
                font-weight: bold;
                line-height: 1.6;
                letter-spacing: .01em;
                
                @include media_query(){
                    @include rem(28);
                }
            }
            
            p{
                @include rem(18);
                font-weight: bold;
                color: $main-color;
                line-height: 2;
                margin-top: 60px;
                
                @include media_query(){
                    @include rem(16);
                    margin-top: 45px;
                    
                    br{
                        display: none;
                    }
                }
                
            }
        }
    }
}





.business{
    background-color: #f5f5f3;
    padding: 115px 20px 100px;
    
    @include media_query(){
        padding: 60px 25px 100px;
    }
    
    .business_inner{
        max-width: 885px;
        margin: 0 auto;
        
        h2{
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            color: $main-color;
            @include rem(78);
            
            @include media_query(){
                @include rem(56);
            }
            
            span{
                display: block;
                font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                font-weight: bold;
                @include rem(16);
                letter-spacing: .04em;
                
                @include media_query(){
                    @include rem(15);
                }
            }
        }
        
        .section_main{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0 18.757%;
            max-width: 800px;
            margin: 50px auto 0;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
            
            .business_item{
                display: grid;
                grid-template-rows: subgrid;
                grid-row: span 3;
                
                img{
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                    
                    @include media_query(){
                        max-width: 278px;
                        margin: 0 auto;
                    }
                }
                
                h3{
                    margin-top: 40px;
                    text-align: center;
                    @include rem(26);
                    line-height: 1.4;
                    color: $main-color;
                    align-self: center;
                    font-weight: bold;
                }
                
                p{
                    margin-top: 30px;
                    @include rem(16);
                    line-height: 1.8;
                    color: $main-color;
                    text-align: justify;
                }
                
                & + .business_item{
                    margin-top: 0;
                    
                    @include media_query(){
                        margin-top: 75px;
                    }
                }
            }
        }
    }
}






.overview{
    padding: 115px 20px 100px;
    background-color: #fff;
    
    @include media_query(){
        padding: 60px 25px 100px;
    }
    
    .overview_inner{
        
        max-width: 885px;
        margin: 0 auto;
        
        .section_main{
            .overview_dl{
                display: grid;
                grid-template-columns: 145px 1fr;
                // gap: 0 25px;
                
                @include media_query(1040){
                    grid-template-columns: 75px 1fr;
                    padding: 0 25px;
                }
                
                @include media_query(){
                    padding: 0;
                }
                
                dt,
                dd{
                    border-top: solid 1px #ccc;
                    padding: 30px 0;
                    @include rem(15);
                    letter-spacing: .02em;
                    color: $main-color;
                    
                    @include media_query(){
                        @include rem(14);
                    }
                }
                
                dt{
                    font-weight: bold;
                    
                    &:last-of-type{
                        border-bottom: solid 1px #ccc;
                    }
                }
                
                dd{
                    padding: 28px 0;
                    line-height: 1.8;
                    
                    &:last-of-type{
                        border-bottom: solid 1px #ccc;
                    }
                    
                    strong{
                        font-weight: bold;
                    }
                }
            }
        }
    }
}






.contact{
    padding: 115px 20px 100px;
    background-color: #f5f5f3;
    
    @include media_query(){
        padding: 60px 25px 100px;
    }
    
    .contact_inner{
        max-width: 885px;
        margin: 0 auto;
        
        &.harf_section{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 150px;
            
            @include media_query(){
                grid-template-columns: 1fr;
                gap: 75px;
            }
            
            .section_title{
                h2{
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    color: $main-color;
                    @include rem(78);
                    
                    @include media_query(){
                        @include rem(56);
                    }
                    
                    span{
                        display: block;
                        font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
                        font-weight: bold;
                        @include rem(16);
                        letter-spacing: .04em;
                        
                        @include media_query(){
                            @include rem(15);
                        }
                    }
                }
            }
        }
        
        .section_main{
            h3{
                @include rem(16);
                line-height: 1.8;
                color: $main-color;
            }
            
            p{
                display: flex;
                align-items: center;
                margin-top: 40px;
                @include rem(32);
                color: $main-color;
                
                @include media_query(){
                    justify-content: center;
                }
                
                & + p{
                    margin-top: 10px;
                }
                
                span{
                    @include rem(21);
                    margin-right: 1em;
                    
                    @include media_query(){
                        margin-right: .3em;
                    }
                }
                
                small{
                    @include rem(13);
                }
            }
            
            .tel{
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                text-decoration: none;
                
                a{
                    text-decoration: none;
                }
            }
        }
    }
    
}





.sp_br{
    display: none;
    
    @include media_query(){
        display: block;
    }
}
